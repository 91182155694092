import React, { useEffect, useState } from "react";
import "./Features.scss";
import iphone7 from "../../assets/Images/features/iPhone 27.svg";
import podcastImg from "../../assets/Images/features/podcasts.svg";
import videosImg from "../../assets/Images/helpingWordsSection/videosCard.svg";
import blogsImg from "../../assets/Images/helpingWordsSection/blogCard.svg";
// smart search section
import backgroundImageIphone from "../../assets/Images/features/smart search background iPhone 30.svg";
import forwardImageIphone from "../../assets/Images/features/smart search forward iPhone 29.svg";
import usinginterestIPhone from "../../assets/Images/features/using interest iPhone.svg";
import bio from "../../assets/Images/features/bio.svg";
import friends from "../../assets/Images/features/friends.svg";
import groups from "../../assets/Images/features/groups.svg";
// single app
import singleForwardImageIphone from "../../assets/Images/features/single app forward iPhone.svg";
import singleBackgroundImageIphone from "../../assets/Images/features/single app backward iPhone.svg";
// colabration
import colabrationImageIphone from "../../assets/Images/features/colabration iphone.svg";
import hart from "../../assets/Images/features/hart.svg";
import bookmark from "../../assets/Images/features/bookmark.svg";
import redirect from "../../assets/Images/features/redirect.svg";
import notes from "../../assets/Images/features/notes.svg";

import link_detail_share1 from "../../assets/Images/features/link_detail_share1.svg";
import link_detail_share2 from "../../assets/Images/features/link_detail_share2.svg";
import link_detail_share3 from "../../assets/Images/features/link_detail_share3.svg";
import link_detail_share4 from "../../assets/Images/features/link_detail_share4.svg";
import axios from "axios";

const baseUrl = "https://api-uyccukj4rq-uc.a.run.app";

// Email validation function
const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const Features = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Clear previous timeout
    if (debounceTimeout) {
      setError();
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to validate the email after 1 second
    const newTimeout = setTimeout(() => {
      if (!validateEmail(value)) {
        setError("Invalid email format");
      } else {
        setError();
      }
    }, 1000); // 1 second delay

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
    if (failed) {
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    }
  }, [success, failed]);

  const submitEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(baseUrl + "/users/waitlist", { email });
      setSuccess(true);
      setEmail();
    } catch (error) {
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="feature-section">
      <div className="background-shadow background-shadow-section1  container"></div>
      <div className="background-shadow background-shadow-section2  container"></div>

      <div className="container feature-container">
        {/* <h1 className="heading">Features</h1> */}
        <div className="content-images-container">
          <div className="slide-image-text">
            <div className="feat-image-container">
              <div className="combine-images">
                <img className="main-iphone" src={iphone7} alt="" />
                <img className="videosImg" src={videosImg} alt="" />
                <img className="podcastImg" src={podcastImg} alt="" />
                <img className="blogsImg" src={blogsImg} alt="" />
              </div>
            </div>
            <div className="feat-text-conainer">
              <h6>Unified app for content cross-platform</h6>
              <p>
                No more scatter of links across note taking apps or chats.
                Consolidate content in one place to explore later,save or share
                with others.
              </p>
            </div>
          </div>

          <div className="slide-image-text smart-search-subsection">
            <div className="feat-text-conainer">
              <h6>Share content in a way it is not forgotten</h6>
              <p>
                Unlike other messenger apps, share content across platforms
                directly with friends, the way you do with reels but for
                everything!
              </p>
            </div>
            <div className="feat-image-container smart-search-images">
              <div className="combine-images">
                <img
                  className="forwardImageIphone"
                  src={backgroundImageIphone}
                  alt=""
                />
                <img
                  className="link_detail_share1"
                  src={link_detail_share1}
                  alt=""
                />
                <img
                  className="link_detail_share2"
                  src={link_detail_share2}
                  alt=""
                />
                <img
                  className="link_detail_share3"
                  src={link_detail_share3}
                  alt=""
                />
                <img
                  className="link_detail_share4"
                  src={link_detail_share4}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="slide-image-text using-interest-subsection">
            <div className="feat-image-container">
              <div className="combine-images">
                <img className="main-iphone" src={usinginterestIPhone} alt="" />
                <img className="bio" src={bio} alt="" />
                <img className="friends" src={friends} alt="" />
                <img className="groups" src={groups} alt="" />
              </div>
            </div>
            <div className="feat-text-conainer">
              <h6>Build an identity around the content you absorb</h6>
              <p>
                Elevate your profile by broadcasting your favorite content to
                your connections with amp.
              </p>
            </div>
          </div>

          <div className="slide-image-text colabration-subsection">
            <div className="feat-text-conainer">
              <h6>
                Wish you could <span>save</span> your thoughts for the content
                you consume?
              </h6>
              <p>
                Amp allows making notes for each saved item right there with all
                of your content.
              </p>
            </div>
            <div className="feat-image-container">
              <div className="combine-images">
                <img
                  className="main-iphone"
                  src={colabrationImageIphone}
                  alt=""
                />
                <img className="hart" src={hart} alt="" />
                <img className="bookmark" src={bookmark} alt="" />
                <img className="redirect" src={redirect} alt="" />
                <img className="notes" src={notes} alt="" />
                {/* <img className="linked1Img" src={linked1} alt="" />
                <img className="linked2Img" src={linked2} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-shadow container"></div>
      {/* <button className="join-btton">Join the wait list</button> */}

      <div className="join-btton emailId-buttom-container">
        <div className="emailId-buttom">
          <input
            disabled={loading}
            style={{
              color: error ? "red" : "black",
              width: success || failed ? 0 : "19.69869rem",
              padding: success || failed ? 0 : "16px",
              marginRight: success || failed ? 0 : "-25px",
            }}
            type="text"
            placeholder="Email"
            value={email}
            onChange={!loading ? handleChange : null}
          />
          <button
            onClick={submitEmail}
            disabled={loading}
            style={{
              backgroundColor: success
                ? "#1d8512"
                : failed
                ? "#dc3545"
                : "#8237ff",
            }}
          >
            {!loading ? (
              success ? (
                "Done! 👍🏻"
              ) : failed ? (
                "Try Again!"
              ) : (
                "Join waitlist"
              )
            ) : (
              <div class="spinner-border" role="status"></div>
            )}
          </button>
        </div>
      </div>
      {/* <div className="join-btton emailId-buttom-container">
        <div className="emailId-buttom">
          <input type="text" placeholder="Email" />
          <button>Join waitlist</button>
        </div>
      </div> */}
    </section>
  );
};

export default Features;
