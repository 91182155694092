import React from "react";
import "./SaveShareAmp.scss";
const SaveShareAmp = () => {
  return (
    <section className="save-shar-amp-section">
      <div className="container save-shar-amp-container">
        <h3>
          Unlike any other <br /> Productivity Platform
        </h3>
        <div className="heading-container">
          <h2>Save It</h2>
          <h2>Share It</h2>
          <h2>Amp It</h2>
        </div>
      </div>
    </section>
  );
};

export default SaveShareAmp;
