import React, { useEffect, useState } from "react";
import "./helpingWorld.scss";
import videosImg from "../../assets/Images/helpingWordsSection/videosCard.svg";
import blogImg from "../../assets/Images/helpingWordsSection/blogCard.svg";
import travelImg from "../../assets/Images/helpingWordsSection/travelCard.svg";
import notesImg from "../../assets/Images/helpingWordsSection/notesCard.svg";
import iphoneImg from "../../assets/Images/helpingWordsSection/iPhone 17.svg";
import axios from "axios";

const baseUrl = "https://api-uyccukj4rq-uc.a.run.app";

// Email validation function
const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const HelpingWorld = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Clear previous timeout
    if (debounceTimeout) {
      setError();
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to validate the email after 1 second
    const newTimeout = setTimeout(() => {
      if (!validateEmail(value)) {
        setError("Invalid email format");
      } else {
        setError();
      }
    }, 1000); // 1 second delay

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
    if (failed) {
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    }
  }, [success, failed]);

  const submitEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(baseUrl + "/users/waitlist", { email });
      setSuccess(true);
      setEmail();
    } catch (error) {
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="experience-content-container container">
      <h1 className="heading-experience">
        Experience content <br /> like never before.
      </h1>
      <p className="para-experience">
        In a world where every click and scroll feeds our <br /> minds, the
        content we absorb molds who we are.
      </p>

      {/* 
      <div className="background-circles-container">
        <div className="outer-circle">
          <div className="inner-circle"></div>
        </div>
      </div> */}
      {/* <img className="imges videosImg" src={videosImg} alt="" />
      <img className="imges blogImg" src={blogImg} alt="" />
      <img className="imges travelImg" src={travelImg} alt="" />
      <img className="imges notesImg" src={notesImg} alt="" />
      <img className="imges iphoneImg" src={iphoneImg} alt="" /> */}

      <div className="emailId-buttom-container">
        <div className="emailId-buttom">
          <input
            disabled={loading}
            style={{
              color: error ? "red" : "black",
              width: success || failed ? 0 : "19.69869rem",
              padding: success || failed ? 0 : "16px",
              marginRight: success || failed ? 0 : "-25px",
            }}
            type="text"
            placeholder="Email"
            value={email}
            onChange={!loading ? handleChange : null}
          />
          <button
            onClick={submitEmail}
            disabled={loading}
            style={{
              backgroundColor: success
                ? "#1d8512"
                : failed
                ? "#dc3545"
                : "#8237ff",
            }}
          >
            {!loading ? (
              success ? (
                "Done! 👍🏻"
              ) : failed ? (
                "Try Again!"
              ) : (
                "Join waitlist"
              )
            ) : (
              <div class="spinner-border" role="status"></div>
            )}
          </button>
        </div>
      </div>

      <div className="blur-circle"></div>
    </section>
  );
};

export default HelpingWorld;
