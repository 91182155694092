import React from "react";
import "./Stats.scss";
import groupOfBoys from "../../assets/Images/statsBanner/groupOfBoys.svg";
const Stats = () => {
  return (
    <section className="stats-section">
      <div className="stats-main-container container">
        {/* <div className="percent-text-container">
          <div className="percent-text">
            <div className="percentage">72%</div>
            <div className="text">
              contributed the most to their screen time and they all doom
              scrolled more often than they’d like
            </div>
          </div>

          <div className="percent-text">
            <div className="percentage">50%</div>
            <div className="text">
              users were not satisfied with their daily content diet
            </div>
          </div>
        </div>
        <div className="vertical-data">
          <div className="percent-text">
            <div className="percentage">66%</div>
            <div className="text">
              of users were interested in consuming more quality content based
              on interests they have or want to develop.
            </div>
          </div>
        </div> */}
        <div className="left-level">
          <div className="childrens percent72">
            <div className="percent72-img"></div>
            <div className="head-text percent-head-text72">
              <span>72%</span>
              <p>
                of the users contributed the most to their screen time and they
                all doom scrolled more often than they’d like
              </p>
            </div>
          </div>
          <div className="childrens percent66">
            <div className="percent66-img"></div>
            <div className="head-text percent-head-text66">
              <span>66%</span>
              <p>
                of users were interested in consuming more quality content based
                on interests they have or want to develop.
              </p>
            </div>
          </div>
        </div>
        <div className="right-level percent50">
          <div className="percent50-img"></div>
          <div className="head-text percent-head-text50">
            <span>50%</span>
            <p>users were not satisfied with their daily content diet</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
