import React from "react";
import "./header.scss";
import LOGO from "../../assets/Images/logo.svg";
const Header = () => {
  return (
    <div className="header-container">
      <div className="container-sm head">
        <img className="head-logo img-fluid" src={LOGO} alt="" />
        <button className="join-btton">Join the wait list</button>
      </div>
    </div>
  );
};

export default Header;
