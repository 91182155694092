import React from "react";
import "./Founders.scss";
import grayCircle from "../../assets/Images/founders/gray circle.svg";
import logo from "../../assets/Images/logo.svg";
import profile from "../../assets/Images/founders/profile-img.svg";

const Founders = () => {
  return (
    <section className="founder-section ">
      <div className="container founder-container">
        <div className="containing-circles-text">
          <div className="logo-persons-circles">
            <div className="first-circle">
              <div className="second-circle">
                <div className="third-circle">
                  <img className="main-logo" src={logo} alt="" />
                </div>
              </div>
              <img className="circle-img img1" src={profile} alt="" />
              <img className="circle-img img2" src={profile} alt="" />
              <img className="circle-img img3" src={profile} alt="" />
              <img className="circle-img img4" src={profile} alt="" />
              <img className="circle-img img5" src={profile} alt="" />
              <img className="circle-img img6" src={profile} alt="" />
            </div>
          </div>
          <h5>
            Amp addresses the challenge of finding and sharing valuable online
            content, ensuring meaningful knowledge is both discoverable and
            shareable.
          </h5>
        </div>
        <h3>
          Minds who <span className="highlite">amp</span>ed this idea
        </h3>
        <div className="founder-card-container">
          <div className="founder-name-img">
            <div className="img-container">
              <img src={grayCircle} alt="" />
            </div>
            <div className="name-designation-container">
              <span className="name">Hussain</span>
              <span className="designation">Founder</span>
            </div>
          </div>
          <div className="founder-name-img">
            <div className="img-container">
              <img src={grayCircle} alt="" />
            </div>
            <div className="name-designation-container">
              <span className="name">Muskan</span>
              <span className="designation">Founder</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Founders;
