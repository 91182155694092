import React from "react";
import "./FreqAskQuestion.scss";
import Accordion from "react-bootstrap/Accordion";

const FreqAskQuestion = () => {
  return (
    <section className="ask-question-section">
      <div className="container ask-question-container">
        <div className="question-text-block">
          <h3>Frequently Asked Questions</h3>
          {/* <div className="question-contact-container">
            <div className="the-question">Still have questions?</div>
            <p>
              You can contact us <span>here</span>.
            </p>
          </div> */}
        </div>
        <div className="question-accordion-main-container">
          <Accordion className="accordion-container">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {/* <span>Q.</span>{" "} */}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiu tempor incididunt ut labore et dol
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiu tempor incididunt ut labore et dol
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {/* <span>Q.</span>{" "} */}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiu tempor incididunt ut labore et dol
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiu tempor incididunt ut labore et dol
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {/* <span>Q.</span>{" "} */}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiu tempor incididunt ut labore et dol
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiu tempor incididunt ut labore et dol
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {/* <span>Q.</span>{" "} */}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiu tempor incididunt ut labore et dol
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiu tempor incididunt ut labore et dol
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FreqAskQuestion;
