import React, { useEffect, useState } from "react";
import "./Footer.scss";
import logo from "../../assets/Images/logo.svg";
import facebook from "../../assets/Images/footer/facebook.svg";
import x from "../../assets/Images/footer/x.svg";
import insta from "../../assets/Images/footer/insta.svg";
import inked from "../../assets/Images/footer/inked.svg";
import axios from "axios";

const baseUrl = "https://api-uyccukj4rq-uc.a.run.app";

// Email validation function
const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Clear previous timeout
    if (debounceTimeout) {
      setError();
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to validate the email after 1 second
    const newTimeout = setTimeout(() => {
      if (!validateEmail(value)) {
        setError("Invalid email format");
      } else {
        setError();
      }
    }, 1000); // 1 second delay

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
    if (failed) {
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    }
  }, [success, failed]);

  const submitEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(baseUrl + "/users/waitlist", { email });
      setSuccess(true);
      setEmail();
    } catch (error) {
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="footer-section">
      <div className="container footer-container">
        <img className="footer-logo" src={logo} alt="" />
        <div className="email-pages-terms-container">
          <div className="email-social-container">
            <div className="emailId-buttom">
              <input
                disabled={loading}
                style={{
                  color: error ? "#ff4747" : "white",
                  width: success || failed ? 0 : "19.69869rem",
                  padding: success || failed ? 0 : "16px",
                  marginRight: success || failed ? 0 : "-25px",
                }}
                type="text"
                placeholder="Email"
                value={email}
                onChange={!loading ? handleChange : null}
              />
              <button
                onClick={submitEmail}
                disabled={loading}
                style={{
                  backgroundColor: success
                    ? "#1d8512"
                    : failed
                    ? "#dc3545"
                    : "#8237ff",
                }}
              >
                {!loading ? (
                  success ? (
                    "Done! 👍🏻"
                  ) : failed ? (
                    "Try Again!"
                  ) : (
                    "Join waitlist"
                  )
                ) : (
                  <div class="spinner-border" role="status"></div>
                )}
              </button>
            </div>
            <div className="socials">
              <button className="social-button">
                <img src={facebook} alt="" />
              </button>
              <button className="social-button">
                <img src={x} alt="" />
              </button>
              <button className="social-button">
                <img src={insta} alt="" />
              </button>
              <button className="social-button">
                <img src={inked} alt="" />
              </button>
            </div>
          </div>
          <div className="pages-link-container">
            <span>About</span>
            <span>Features</span>
            <span>Origin</span>
            <span>Solution Area</span>
            <span>Careers</span>
            <span>Blogs</span>
            <span>Contact Us</span>
          </div>
          <div className="tearms-policy-container">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
          </div>
        </div>
        <span className="all-right-text">
          All rights reserved to amp.founders@amp.com
        </span>
      </div>
    </section>
  );
};

export default Footer;
