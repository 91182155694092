import "./App.css";
import Features from "./components/Features/Features";
import Footer from "./components/Footer/Footer";
import Founders from "./components/Founders/Founders";
import FreqAskQuestion from "./components/FreqAskQuestion/FreqAskQuestion";
import Header from "./components/Header/Header";
import HelpingWorld from "./components/HelpingWorld/HelpingWorld";
import ReadyToAmplify from "./components/ReadyToAmplify/ReadyToAmplify";
import SaveShareAmp from "./components/SaveShareAmp/SaveShareAmp";
import Stats from "./components/Stats/Stats";

const App = () => {
  return (
    <div className="App">
      <Header />
      <HelpingWorld />
      <Founders />
      <SaveShareAmp />
      <Features />
      <Stats />
      <FreqAskQuestion />
      {/* <ReadyToAmplify /> */}
      <Footer />
    </div>
  );
};

export default App;
